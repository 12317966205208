<script lang="ts">
  import { linebreakToBr } from "scripts/utils/utils";
  import type { Products_InstrumentSpec } from "../types";

  export let instrument: Products_InstrumentSpec;
</script>

<div class="text-muted">
  {#if instrument}
    <div class="row">
      <div class="col-12 col-md-8" style="font-size: 0.8rem;">
        <div>名称： {instrument.name}</div>
        <div>
          概要：<br />
          <div class="px-3">
            {@html linebreakToBr(instrument.description)}
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        {#if instrument.image}
          <img
            src={instrument.image}
            alt="instrument img"
            style="width: 90%; max-width: 10rem;"
          />
        {/if}
      </div>
    </div>
  {/if}
</div>
