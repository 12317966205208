<script lang="ts">
  import { Cell } from "@smui/data-table";
  import type {
    Products_ItemForSearch,
    Products_SongDataForSearch,
  } from "scripts/products/types";

  export let songData: Products_SongDataForSearch;
  export let instrumentId;

  /** 選択されている機種に適合する商品のみを抽出 */
  const filterItemsByInstrumentId = (
    items: Products_ItemForSearch[],
    instId: string | number,
  ) => {
    const filtered = items.filter((item) =>
      item.instrument.map((inst) => inst.id == instId).includes(true),
    );
    // console.log("filtered", filtered);
    return filtered;
  };
</script>

<Cell class="py-2">
  {#if songData}
    <div class="row">
      <div class="col-12 col-sm-5">
        <div class="font-weight-bolder">{songData.name_display}</div>
        <div class="ps-1 smallText">
          作曲: {songData.writer}<br />
          アーティスト: {songData.artist}<br />
          {#if songData.note}
            備考: {songData.note}
          {/if}
        </div>
      </div>
      <div class="col-12 col-sm-7 smallText">
        <div class="ps-1">取り扱い商品:</div>
        <div class="ps-1 ellipsis">
          {#each filterItemsByInstrumentId(songData.items, instrumentId) as product}
            <a href={product.product_url}>
              <span class="">・{product.title}</span>
            </a><br />
          {/each}
        </div>
      </div>
    </div>
  {/if}
</Cell>

<style>
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
