<script lang="ts">
  import DataTable, {
    Head,
    Body,
    Row,
    Cell,
    Pagination,
    SortValue,
  } from "@smui/data-table";
  import { Label } from "@smui/button";
  import Select, { Option } from "@smui/select";
  import { searchFromString } from "scripts/utils/EditableTable_utils";
  import { createEventDispatcher } from "svelte";
  import IconButton from "@smui/icon-button";
  import SongDataCell from "scripts/global_components/TableItem/SongDataCell.svelte";

  export let items;
  export let instrumentId;

  // sort 設定
  let sort = items.length ? String(Object.keys(items[0])[0]) : "id"; // 変数名sortの変更不可

  export let searchable = true;
  export let displayPagination = true;
  export let sortDirection: Lowercase<keyof typeof SortValue> = "ascending";
  let arrow_direction =
    sortDirection === "ascending" ? "arrow_downward" : "arrow_upward";

  export let tableName: string = "";
  let tableFields = ["name_display", "writer", "artist", "items"];
  let tableLabels = ["楽曲"];
  // テーブル表示数の選択肢
  export const rowsSelect = [10, 18, 25, 50, 100];
  export let rowsPerPage = rowsSelect[0];
  let currentPage = 0;
  let slice = [];
  let searchResult = [];
  let searchText = "";

  const dispatch = createEventDispatcher();
  const trClick = (item) => {
    // console.log(item);
    dispatch("trClick", { item: item });
  };
  const handleSort = () => {
    items.sort((a, b) => {
      const [aVal, bVal] = [a[sort], b[sort]][
        sortDirection === "ascending" ? "slice" : "reverse"
      ]();
      // console.log(typeof aVal, a[sort], Object.keys(items[0])[0], sort);

      if (typeof aVal === "string" && typeof bVal === "string") {
        return aVal.localeCompare(bVal);
      }
      return Number(aVal) - Number(bVal);
    });
    items = items;
  };

  let processing = false;
  // 検索窓にテキストが入力された場合の挙動
  const doSeach = (searchText: string) => {
    // 検索処理が行われている間は入力を無視する
    if (processing) {
      return;
    }
    processing = true;
    searchResult = searchFromString(items, searchText, {}, tableFields);
    currentPage = 0;
    processing = false;
  };

  $: if (items) doSeach(searchText);
  $: lastPage = Math.max(Math.ceil(searchResult.length / rowsPerPage) - 1, 0);
  $: if (currentPage > lastPage) {
    currentPage = lastPage;
  }
  $: start = currentPage * rowsPerPage;
  $: end = Math.min(start + rowsPerPage, searchResult.length);
  $: slice = searchResult.slice(start, end);
</script>

<svelte:head>
  <style>
    .mdc-data-table__table {
      white-space: unset;
    }
  </style>
  <link
    rel="stylesheet"
    href="https://unpkg.com/@material/typography@13.0.0/dist/mdc.typography.css"
  />
  <link
    href="https://fonts.googleapis.com/icon?family=Material+Icons"
    rel="stylesheet"
  />
</svelte:head>

<h5 class="font-weight-bold text-muted">{tableName}</h5>
<div class="row">
  <div class="d-flex align-items-center mb-2 col-3 col-sm-3"></div>
  <div class="d-flex align-items-center mb-2 col-8 col-sm-4"></div>
  <div
    class="col-12 col-sm-5 d-flex align-items-center mb-2 justify-content-end"
  >
    {#if searchable}
      <div class="form-floating mb-3 w-100">
        <input
          bind:value={searchText}
          on:input={() => {
            doSeach(searchText);
          }}
          type="text"
          class="form-control w-100"
          placeholder="Search"
        />
        <label class="text-muted" for="floatingInput">検索</label>
      </div>
    {/if}
  </div>
</div>

<DataTable
  sortable
  bind:sort
  bind:sortDirection
  on:SMUIDataTable:sorted={handleSort}
  style="width: 100%;"
  class="editable-datatable"
  container$class="editable-datatable-container"
  table$class=""
>
  <Head>
    <Row>
      {#each tableLabels as label}
        <Cell>
          <span>{label}</span>
          <IconButton size="button" class="material-icons">
            {arrow_direction}
          </IconButton>
        </Cell>
      {/each}
    </Row>
  </Head>
  <Body>
    {#each slice as item}
      <Row on:click={() => trClick(item)}>
        <SongDataCell songData={item} {instrumentId} />
      </Row>
    {/each}
    <slot />
  </Body>
  <Pagination
    slot="paginate"
    style={!displayPagination ? "display: none;" : ""}
  >
    <svelte:fragment slot="rowsPerPage">
      <Label>Rows Per Page</Label>
      <Select variant="outlined" bind:value={rowsPerPage} noLabel>
        {#each rowsSelect as select}
          <Option value={select}>{select}</Option>
        {/each}
      </Select>
    </svelte:fragment>
    <svelte:fragment slot="total">
      <div>
        {start + 1}-{end} of {searchResult.length}
      </div>
    </svelte:fragment>
    <div>
      <IconButton
        class="material-icons"
        action="first-page"
        title="First page"
        style="width: 21%;"
        on:click={() => (currentPage = 0)}
        disabled={currentPage === 0}>first_page</IconButton
      >
      <IconButton
        class="material-icons"
        action="prev-page"
        title="Prev page"
        style="width: 21%;"
        on:click={() => currentPage--}
        disabled={currentPage === 0}>chevron_left</IconButton
      >
      <IconButton
        class="material-icons"
        action="next-page"
        title="Next page"
        style="width: 21%;"
        on:click={() => currentPage++}
        disabled={currentPage === lastPage}>chevron_right</IconButton
      >
      <IconButton
        class="material-icons"
        action="last-page"
        title="Last page"
        style="width: 21%;"
        on:click={() => (currentPage = lastPage)}
        disabled={currentPage === lastPage}>last_page</IconButton
      >
    </div>
  </Pagination>
</DataTable>

<style>
  :global(.editable-datatable-container::-webkit-scrollbar) {
    height: 0.8rem;
  }
  :global(.editable-datatable-container::-webkit-scrollbar-thumb) {
    background: #bcbcbc;
  }
  :global(.smallText) {
    font-size: 0.8rem;
  }
</style>
