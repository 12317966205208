export const pathArr = location.pathname.split('/').filter((val) => val !== '');
export const BACKOFFICE_APP_NAME = pathArr[1];
export const BACKOFFICE_VIEW_NAME = pathArr[2];
export const APP_NAME = pathArr[0]
export const VIEW_NAME = pathArr[1]

const routeBackOffice = `/back-office`;
const routeApp = `${routeBackOffice}/${BACKOFFICE_APP_NAME}`;

export const routeShop = {
    base: '/shop',
    apiBase: '/shop/api',
    apiItem(pk: string = '') { return `${this.apiBase}/item/${pk}` },
    apiOrderItem(pk: string = '') { return `${this.apiBase}/orderitem/${pk}` },
    apiSongSearchHead() { return `${this.apiBase}/songsearch_head/` },
    apiSongData(instrumentId: string = '') { return `${this.apiBase}/songdata/${instrumentId}` },
    songSearch(instrumentId: number | string = '') { return `${this.base}/songsearch/${instrumentId}` },
    orderSummary() { return `${this.base}/order-summary/` },
    quantityChange(pk, newQuantity: string) { return `${this.base}/quantity-change/${pk}/${newQuantity}/` },
}

export const routeBlogger = {
    descriptionJson(pk: string) { return `${routeBackOffice}/blogger/description-json/${pk}/` }
}

export const routeAccounts = {
    base: '/accounts',
    login: `/accounts/login`
}

export const routeProducts = {
    descriptionPart(part_name: string) { return `${routeBackOffice}/products/description-part/blogger/${part_name}/` },
    descriptionJson(pk: string) { return `${routeBackOffice}/products/description-json/${pk}/` },
    descriptionTemplate(pk: string, code: string) { return `${routeBackOffice}/products/description-template/${pk}/${code}` },
    apiItemChoice(pk: string = '') { return `${routeBackOffice}/products/api/item_choice/${pk}` },
}