import type { TypeTableConfig } from "./types";


export const handleSort = (items, sortDirection: string, sort: string) => {
    items.sort((a, b) => {
        const [aVal, bVal] = [a[sort], b[sort]][
            sortDirection === "ascending" ? "slice" : "reverse"
        ]();
        if (typeof aVal === "string" && typeof bVal === "string") {
            return aVal.localeCompare(bVal);
        }
        return Number(aVal) - Number(bVal);
    });
    // console.log(sort, items);
    items = items;
};


// 検索窓にテキストが入力された場合の挙動
export const searchFromString = (
    items: object[],
    searchText: string,
    tableConfig: TypeTableConfig = {},
    tableFields: string[] = []
) => {
    let searchResult
    // tableConfigが設定されているときはtableFieldsに書き換える
    if (Object.keys(tableConfig).length) {
        tableFields = Object.keys(tableConfig);
    }

    if (searchText.length) {
        let searchTextLower = searchText.toLowerCase()
        searchResult = items.filter((item) => {
            let results: boolean[] = [];

            Object.entries(item).forEach(([fieldName, val]) => {
                if (
                    Object.keys(tableConfig).includes(fieldName) &&
                    tableFields.includes(fieldName) &&
                    tableConfig[fieldName].choices
                ) {
                    const choices = tableConfig[fieldName].choices;
                    let result = false;
                    if (choices) {
                        // console.log(choices);
                        const found = choices.find((choice) =>
                            choice.display_name.toLowerCase().includes(searchTextLower),
                        );
                        if (found && found.value === val) result = true;
                    }
                    results.push(result);
                } else if (typeof val === "string") {
                    results.push(val.toLowerCase().includes(searchTextLower));
                } else if (typeof val === "number") {
                    results.push(String(val) == searchText);
                }
            });
            return results.includes(true);
        });
    } else {
        searchResult = items;
    }
    return searchResult
    // console.log('search');
};