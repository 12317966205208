<script lang="ts">
  /** ModelDetailFormの
   */
  import type { FieldConfig } from "scripts/utils/types";
  import InvalidMessage from "./InvalidMessage.svelte";

  export let config: FieldConfig;
  export let value: FileList = null;

  let acceptFile = "image/jpg, image/png";
  let inputElement: HTMLInputElement;
  let invalidMessage = "";
  let focus = false;

  let files: FileList;
  let maxSize = 1 * 1024 * 1024; // 5MB
  let multiple = false;

  const validation = (files?) => {
    let size = 0;
    let errors = "";
    if (files) {
      /* console.log(
        files,
        files instanceof FileList,
        files[0] instanceof File,
        files[0] instanceof Blob,
      );*/
      for (let i = 0; i < files.length; i++) {
        size += files[i].size;
      }
    }

    if (size > maxSize) {
      invalidMessage =
        "ファイルサイズの合計が展開できる最大値を超えています。複数のファイルに分割するか、同時に展開するファイルを少なくしてリトライしてください。\n";
      config.is_valid = false;
    } else {
      if (config.input) {
        config.input.setCustomValidity(config.customValidation(value));
        invalidMessage += config.input.validationMessage;
        config.is_valid = config.input.validity.valid;
      }
    }
    // console.log(value, config.input?.validity, invalidMessage);
  };

  const loadFiles = async (files: FileList) => {
    //if (files) {
    //  const arr = await convertFileToBase64(files);
    //  value = arr;
    //}
    value = files;
  };

  $: config.input = inputElement;
  $: loadFiles(files);
  $: validation(files);
  // $: console.log(value);
</script>

<div class={config.inputClass}>
  <div class="input-group" style="width:100%">
    {#if config.label_display}
      <label for={`id_for_${config.name}`} class="input-group-text"
        >{config.options.label}</label
      >
    {/if}
    <input
      type="file"
      bind:files
      bind:this={inputElement}
      class="form-control"
      accept={acceptFile}
      {multiple}
      placeholder={config.placeHolder}
      style={`${config.inputStyle}`}
      required={config.options.required}
      readonly={config.options.read_only}
      on:focusout={() => validation(value)}
      on:focusout={() => (focus = false)}
      on:focus={() => (focus = true)}
    />
  </div>
  <InvalidMessage
    message={config.input?.validationMessage || config.helperText}
    is_valid={config.is_valid}
    {focus}
  />
</div>
