import { pathArr, APP_NAME, VIEW_NAME } from 'scripts/utils/router'
import CoreRouter from 'scripts/core/CoreRouter.svelte';

let app = undefined
const svComponentEl = document.querySelector('.sv-component')
if (APP_NAME === 'shop' && svComponentEl) {
    app = new CoreRouter({
        target: svComponentEl,
    });
}


export default app;