<script lang="ts">
  import type { FieldConfig } from "scripts/utils/types";
  import InvalidMessage from "./InvalidMessage.svelte";

  export let config: FieldConfig;
  export let value;

  let inputComponent: HTMLInputElement | HTMLTextAreaElement;
  let invalidMessage = "";
  let focus = false;

  const validation = (value?) => {
    if (config.input) {
      // if (value) config.input.value = value; # textinputがバグる
      config.input.setCustomValidity(config.customValidation(value));
      config.is_valid = config.input.validity.valid;

      if (config.input?.validity) {
        invalidMessage = "";
      } else {
        invalidMessage = config.input?.validationMessage;
      }
    }

    // console.log(value, config.input?.validity, config.input?.validationMessage);
  };

  $: config.input = inputComponent;
  $: validation(value);
  // $: console.log(value);
</script>

<!--Textfield
  style="width:100%;"
  input$style={inputStyle}
  bind:value
  bind:input={inputComponent}
  validateOnValueChange={true}
  label={config.options.label}
  required={config.options.required}
  readonly={config.options.read_only}
  input$maxlength={config.options.max_length}
  type={inputType}
  on:focusout={() => validation(value)}
  on:focusout={() => (focus = false)}
  on:focus={() => (focus = true)}
>
</Textfield-->
<div class={config.inputClass}>
  <div class="input-group" style="width:100%">
    {#if config.label_display}
      <label for={`id_for_${config.name}`} class="input-group-text"
        >{config.options.label}</label
      >
    {/if}
    {#if config.options.max_length > 100}
      <textarea
        id="id_for_{config.name}"
        class="form-control"
        placeholder={config.placeHolder}
        style={config.inputStyle}
        maxlength={config.options.max_length}
        required={config.options.required}
        readonly={config.options.read_only}
        rows="4"
        bind:value
        bind:this={inputComponent}
        on:focusout={() => validation(value)}
        on:focusout={() => (focus = false)}
        on:focus={() => (focus = true)}
      />
    {:else}
      <input
        type="text"
        id="id_for_{config.name}"
        class="form-control"
        placeholder={config.placeHolder}
        style={config.inputStyle}
        maxlength={config.options.max_length}
        required={config.options.required}
        readonly={config.options.read_only}
        bind:value
        bind:this={inputComponent}
        on:focusout={() => validation(value)}
        on:focusout={() => (focus = false)}
        on:focus={() => (focus = true)}
      />
    {/if}
  </div>
  <InvalidMessage
    message={config.input?.validationMessage || config.helperText}
    is_valid={config.is_valid}
    {focus}
  />
</div>
