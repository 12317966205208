import type { FieldConfig, FieldOptions, FormConfig } from "./types";



export const sanitizeHtml = (target: string | null) => {
    if (target) {
        const replaced = target
            .replace('&', '&amp;')
            .replace("'", '&#x27;')
            .replace('`', '&#x60;')
            .replace('"', '&quot;')
            .replace('<', '&lt;')
            .replace('>', '&gt;');

        return replaced;
    } else return '';
};

// 新規html要素作成と属性の登録を一緒に行う関数。 createDropdownButton()で使用。
// 属性 : attrs  例：{'class' : 'item w-100}
const ATTR_WHITE_LIST = ["class", "id"];
export const createElwithAttr = (el: string, attrs: Record<string, string>) => {
    var newElement = document.createElement(el);
    for (const [key, value] of Object.entries(attrs)) {
        if (ATTR_WHITE_LIST.includes(key)) {
            newElement.setAttribute(key, value);
        } else if (key === "href" || key === "src") {
            newElement.setAttribute(key, encodeURI(value));
        } else {
            newElement.setAttribute(key, sanitizeHtml(value));
        }
    }
    return newElement;
};


/** 数値を指定の桁数で０字詰めする */
export const zeroPad = (num: number, maxLength: number, padString = '0') => {
    return num.toString().padStart(maxLength, padString)
}


/** 改行をbrに変換 */
export const linebreakToBr = (str) => {
    let result = ''
    if (str) result = str.replace(/(\r\n|\r|\n)/g, '<br>');
    return result
}

/** 文字列を指定の文字数で省略 */
export const truncateString = (str: string, num: number) => {
    if (str.length > num) {
        return str.slice(0, num) + "...";
    } else {
        return str;
    }
}


/** inputコンポーネントのカスタムバリデーションを設定しない場合は常に空のinvalidMessageを返すバリデーションをかませる */
export const defaultValidator = (value) => {
    return '';
};

export const defaultFieldOptions: FieldOptions = {
    type: 'string',
    read_only: false,
    label: '',
    required: false,
};

/** フォームの入力欄の初期設定値に使用できるコンフィグ。
 * スプレッド構文でこの初期値を設定し、カスタマイズしたい値のみを
 * 再度記述することでコードの量を削減できる。
 * 例： customConfig = {...defaultFieldConfig, helperText: 'abc'}
 */
export const defaultFieldConfig: FieldConfig = {
    component: undefined,
    initialValue: undefined,
    placeHolder: '',
    helperText: '',
    options: { ...defaultFieldOptions },
    input: undefined,
    is_valid: false,
    name: '',
    inputType: null,
    inputStyle: null,
    inputClass: 'mt-3',
    label_display: true,
    display: true,
    customValidation: defaultValidator,
}

/** formConfigからRowキーを除き、key: fieldConfig 形式のObjectに変換する */
export const mergeFormConfig = (
    formConfig: FormConfig,
): Record<string, FieldConfig> => {
    let res = {};
    Object.values(formConfig).forEach((conf) => (res = { ...res, ...conf }));
    return res;
};

/** EditableTableで使用。入力されたOjectを指定されたキーの要素で文字列を作成し、返却する。
 *入力はObjectのArrayでも可。 
*/
export const makeObjectStr = (obj: Record<string, any>[] | Record<string, any>, keys: string[] | string) => {
    let returnVal = ''
    if (Array.isArray(obj)) {
        obj.forEach((child) => {
            returnVal.length ? returnVal += ` , ${objToString(child, keys)}` : returnVal = objToString(child, keys)
            if (!returnVal) returnVal = ''
        })
    } else {
        returnVal = objToString(obj, keys)
    }
    return returnVal
}
const objToString = (obj: Record<string, any>, keys: string[] | string) => {
    let returnVal = ''
    if (Array.isArray(keys)) {
        keys.forEach((key) => {
            returnVal.length ? returnVal += `(${obj[key]}) ` : returnVal = `${obj[key]} `
        })
    } else returnVal = obj[keys]
    return returnVal
}

/** blobデータをダウンロードする。blob -> urlに変換 -> aタグを新規作成してhrefにセット -> ダウンロードする */
export const downloadBlob = (blob, fileName: string) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.download = fileName;
    a.href = url;
    a.click();
    a.remove();
    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 1E4);
}

/** ユーザーのデバイスがモバイルかを判断 */
export const isMobileDevice = (): boolean => {
    const userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

// ピクセルをremに変換する関数
export const pxToRem = (element: HTMLElement, px: number): string => {
    const rootFontSize = parseFloat(getComputedStyle(element).fontSize);
    const remValue = px / rootFontSize;
    return remValue + "rem";
}