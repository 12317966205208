<script lang="ts">
  import type { FieldConfig } from "scripts/utils/types";

  import { onMount } from "svelte";
  import InvalidMessage from "./InvalidMessage.svelte";
  import { dateToStr } from "scripts/utils/date_utils";

  export let value;
  let inputValue;
  export let config: FieldConfig;
  let focus = false;

  onMount(() => {
    validation();
  });

  const validation = (value?) => {
    config.invalidMessage = config.input?.validationMessage;
    config.is_valid = config.input?.validity.valid;

    // console.log(value);

    // console.log(value, config.input?.validity?.valid, config.invalidMessage);
  };

  const formatDate = (inputValue) => {
    if (inputValue) value = dateToStr(new Date(inputValue)).YMDformat;
  };
  const valueChanged = (value) => {
    if (value) inputValue = dateToStr(new Date(value)).YMDformat;
  };

  $: formatDate(inputValue);
  $: valueChanged(value);
  $: validation(inputValue);
</script>

<div class={config.inputClass}>
  <div class="input-group">
    {#if config.label_display}
      <label for={`id_for_${config.name}`} class="input-group-text"
        >{config.options.label}</label
      >
    {/if}
    <input
      id={`id_for_${config.name}`}
      class="font-weight-bold form-control text-muted"
      type="date"
      bind:value={inputValue}
      placeholder={config.placeHolder || "2020/01/11"}
      required={config.options.required}
      readonly={config.options.read_only}
      bind:this={config.input}
      on:focusout={() => validation(value)}
      on:focusout={() => (focus = false)}
      on:focus={() => (focus = true)}
    />
  </div>
  <InvalidMessage
    message={config.input?.validationMessage || config.helperText}
    is_valid={config.is_valid}
    {focus}
  />
</div>
