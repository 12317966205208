<script lang="ts">
  import FadeCollapse from "scripts/global_components/FadeCollapse.svelte";
  import type { Products_InstrumentSpec } from "scripts/products/types";
  import { fetchGetRequest } from "scripts/utils/fetcher";
  import { routeShop } from "scripts/utils/router";
  import { onMount } from "svelte";
  import { Link } from "svelte-routing";

  let endpoint: string = routeShop.apiSongSearchHead();
  let instrumentSpecs: Record<string, Products_InstrumentSpec[]> = {};
  let currentInstrumentId: number;

  onMount(() => {
    makeDataSets();
  });

  const makeDataSets = async () => {
    const res = await fetchGetRequest({
      endpoint: endpoint,
      errorMessage: "の取得に失敗しました。",
      params: {},
    });
    if (res.success) instrumentSpecs = res.data;
  };
</script>

<main class="mx-lg-5">
  <div class="container-fluid mt-3 px-2 px-md-4">
    <div class="row mb-4">
      <div class="col-12">
        <h2 class="text-muted my-3">楽曲検索 機種選択</h2>
        <p class="px-3">
          当社で提供する商品を楽曲の情報から検索していただくことができます。<br
          />
          下記の中からお探しの楽器の機種を選択すると曲目リストが表示されます。
        </p>
      </div>
    </div>
    <div class="row px-3">
      <div class="col-12">
        <div id="instruments">
          {#each Object.entries(instrumentSpecs) as [category, instList]}
            <div class="mb-4">
              <h3 class="text-muted border-bottom">{category}</h3>
              <div class="px-3">
                {#each instList as inst}
                  <div class="">
                    <Link to={routeShop.songSearch(inst.id)}>{inst.name}</Link>
                  </div>
                {/each}
              </div>
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</main>

<!--style>
  .song {
    border: 0 solid #ffff;
    background-color: #ffff;
  }
</style-->
