import { writable } from "svelte/store";
import type { TypeTableConfig } from "scripts/utils/types";

type ItemChoiceId = number
type SelectedValue = {
    value: number | number[] | string
}
export type ItemChoiceStore = Record<ItemChoiceId, SelectedValue>

export const itemChoiceStore = writable<ItemChoiceStore>({})

export const songSearchTableConfig: TypeTableConfig = {
    name_display: {
        label: '曲名',
        type: 'string',
        editable: false,
    },
    writer: {
        label: '作曲者',
        type: 'string',
        editable: false,
    },
    artist: {
        label: 'アーティスト',
        type: 'string',
        editable: false,
    },
    items: {
        label: '機種',
        type: 'object',
        editable: false,
        objectKey: ['title', 'product_url']
    },
};