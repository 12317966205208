<script lang="ts">
  import FadeCollapse from "../FadeCollapse.svelte";

  export let message = "";
  export let is_valid = true;
  export let focus = false;
  let classChar = "error-color";
  let expand = false;

  const changeClass = (is_valid, focus) => {
    is_valid ? (classChar = "") : (classChar = "error-color");
    (message.length > 0 && !is_valid) || focus
      ? (expand = true)
      : (expand = false);
    // console.log(is_valid, focus, message);
  };

  $: changeClass(is_valid, focus);
</script>

<div class="errorMessage">
  <FadeCollapse {expand} inDuration={200}>
    <div class="message {classChar}">{message}</div>
  </FadeCollapse>
</div>

<style>
  .errorMessage {
    min-height: 1.5rem;
  }
  .message {
    font-size: 0.8rem;
    text-align: left;
  }
</style>
