import type { ChoiceDisplayMap, FieldConfig, ResultWithData, StandardResult, TableStatus, TableStatusRow, TableStatusVal, TypeTableConfig, TypeTableConfigVal } from "./types";
import { makeObjectStr } from "./utils";

export const downloadCSV = (filename: string, objList: object[], exportFields: string[], tableConfig: TypeTableConfig, choiceDisplayMap: ChoiceDisplayMap) => {
  //CSVデータ
  const data = ObjToCSV(objList, exportFields, tableConfig, choiceDisplayMap);
  //BOMを付与する（Excelでの文字化け対策）
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  //Blobでデータを作成する
  const blob = new Blob([bom, data], { type: "text/csv" });

  //BlobからオブジェクトURLを作成する
  const url = (window.URL || window.webkitURL).createObjectURL(blob);
  //ダウンロード用にリンクを作成する
  const download = document.createElement("a");
  //リンク先に上記で生成したURLを指定する
  download.href = url;
  //download属性にファイル名を指定する
  download.download = filename;
  //作成したリンクをクリックしてダウンロードを実行する
  download.click();
  //createObjectURLで作成したオブジェクトURLを開放する
  (window.URL || window.webkitURL).revokeObjectURL(url);

}

/** ObjectのArrayからCSVデータを作成する */
const ObjToCSV = (objList: object[], exportFields: string[], tableConfig: TypeTableConfig, choiceDisplayMap: ChoiceDisplayMap) => {
  const labels = []
  exportFields.forEach(fieldName => {
    labels.push(tableConfig[fieldName].label)
  })
  // header
  let dataText = labels.join(',') + '\n'
  objList.forEach(obj => {
    // const valArray = []
    const valArray = exportFields.map(fieldName => {
      let value: string | number = ''
      if (tableConfig[fieldName].type === 'choices') {
        value = getChoiceDisplay(choiceDisplayMap, fieldName, obj[fieldName])
      } else if (['field', 'object'].includes(tableConfig[fieldName].type)) {
        const includeComma = makeObjectStr(obj[fieldName], tableConfig[fieldName].objectKey)
        value = `\"${includeComma.replace(',', '')}\"`
      } else value = obj[fieldName]
      return value
    })
    dataText += valArray.join(',') + '\n'
  })
  // console.log(objList, dataText)
  return dataText
}

export const getChoiceDisplay = (
  choiceDisplayMap: ChoiceDisplayMap,
  fieldName: string,
  val: number | string,
) => {
  //console.log('chocies ', config.choices);
  if (Object.keys(choiceDisplayMap).includes(fieldName) && Object.keys(choiceDisplayMap[fieldName]).includes(String(val))) {
    return choiceDisplayMap[fieldName][val]
  }
  else { return val ? val : '' }
};


/** 一括更新可能なFieldごとにINITIAL_TABLE_STATUS_VALUEを代入し、TableStatusRowを作成する */
const INITIAL_TABLE_STATUS_VALUE = { color: '', value: undefined }
export const makeInitialTableStatusRow = (bulkUpdateFields: string[]) => {
  const returnVal: TableStatusRow = {}
  bulkUpdateFields.forEach((fieldName) => {
    returnVal[fieldName] = INITIAL_TABLE_STATUS_VALUE
  })
  return returnVal
}

/** 一括更新用のデータを初期化 */
export const initTableStatus = (tableStatus: TableStatus, bulkUpdateFields: string[]) => {
  Object.keys(tableStatus).forEach((pk) => {
    tableStatus[pk] = makeInitialTableStatusRow(bulkUpdateFields)
  });
  // console.log(tableStatus);
};


/** CSV登録 */

/** csv一括登録時に使用 CSVのカラム名とモデルのFieldNameの対応表 */
export const makeColumnNameFieldNameMap = (exportFields: string[], tableConfigs: TypeTableConfig) => {
  const resultMap = {}
  exportFields.forEach(fieldName => {
    resultMap[tableConfigs[fieldName].label] = fieldName
  })
  return resultMap
}

/** CSVを解析し、１行目にTableConfig.labelのArrayを持つテーブル形式のデータに変換する。 
 * retrun parsedData = [
 *  [label, label, ...]
 *  [value, value, ...]
 *  [value, value, ...] 
 * ]
 * 各行のArrayの長さは全て同じになる。
*/
export const parseCSV = (loadedFiles: string[]) => {
  const data: (string | number)[][] = []
  let row: (string | number)[] = []
  let prev = '' // 直前のchunk
  const file = loadedFiles[0]

  const chunks = file.match(/,|\r?\n|[^,"\r\n]+|"(?:[^"]|"")*"/g)
  chunks.forEach(chunk => {
    if (chunk != ',') {
      if (chunk.match(/\r?\n$/)) {
        if (prev == ',') {
          row.push('')
        }
        data.push(row)
        row = []
      } else {
        if (chunk[0] == '"' && chunk[-0] == '"') {
          row.push(chunk.slice(1, -1))
        } else row.push(chunk)
      }
    } else {
      // ','が2回続いた場合は空白セルがあると見做して空白文字をrowに加える
      if (prev == ',') {
        row.push('')
      }
    }
    prev = chunk
  });
  if (row.length) data.push(row)
  // console.log(data);
  return data
}

/** parseCSV()で変換したデータをアップロード可能な形式に変換する。
 * 1行目はTableConfig.labelになっているのをfieldNameに変換。
 * TableConfig.type == 'choices'になっているものは表示名になっているのでこの段階でchoices.valueの値に変換し、サーバー側の処理を軽減する。
 * data = [
 *  [fieldName, fieldName, ...]
 *  [value, value, ...]
 *  [value, value, ...] 
 * ]
 */
export const convertToUploadData = (
  data: (string | number)[][],
  fieldConfigs: Record<string, FieldConfig>,
  tableConfigs: TypeTableConfig,
): ResultWithData => {
  let errors = []
  const dataRows = data.slice(1)
  const labelRow = data[0]
  const labelKeyMap = {}
  Object.entries(tableConfigs).forEach(([fieldName, config]) => labelKeyMap[config.label] = fieldName)
  const fieldNames = labelRow.map(label => labelKeyMap[label])
  // choicesのvalueを適用
  const convertedData = []

  dataRows.forEach((dataRow, rowIdx) => {
    convertedData.push(dataRow.map((displayValue, idx) => {
      const fieldName = fieldNames[idx]
      const config = tableConfigs[fieldName]
      const fieldConfig = fieldConfigs[fieldName]
      if (config.type == 'choices') {
        // console.log(config, displayValue);
        const choice = fieldConfig.options.choices.find(choice => choice.display_name == displayValue)
        if (!choice && fieldConfig.options.required) errors.push(`選択肢にないデータが入力されています。${rowIdx + 1}行目-${data[0][idx]}: ${displayValue}`)
        return choice ? choice.value : ''
      } else return displayValue
    }))
  })
  // console.log(convertedData);

  return {
    success: errors.length ? false : true,
    data: [fieldNames, ...convertedData],
    error: errors
  }
}