<script lang="ts">
  import EditableDataTable from "scripts/global_components/EditableDataTable.svelte";
  import { fetchGetRequest } from "scripts/utils/fetcher";
  import { routeShop } from "scripts/utils/router";
  import { Cell } from "@smui/data-table";

  import { onMount } from "svelte";
  import type { Products_SongDataForSearch } from "./types";
  import { songSearchTableConfig } from "./store";
  import ModelSpec from "./child/ModelSpec.svelte";
  import type { Products_InstrumentSpec } from "scripts/products/types";
  import { navigate } from "svelte-routing";
  import SongSearchTable from "./child/SongSearchTable.svelte";

  export let params: Record<string, string>;
  let instruments = []; // 機種一覧
  let instrument: Products_InstrumentSpec; // 現在選択中の機種

  let itemList: Products_SongDataForSearch[] = [];
  let tableItems: Products_SongDataForSearch[];

  onMount(() => {
    makeDataSets();
  });

  const makeDataSets = async () => {
    const tableHeaderRes = await fetchGetRequest({
      endpoint: routeShop.apiSongSearchHead(),
      errorMessage: "tableHeaderの取得に失敗しました。",
      params: {},
    });
    if (tableHeaderRes.success) {
      Object.values(tableHeaderRes.data).forEach((instList) => {
        instruments = instruments.concat(instList);
      });
    }

    const res = await fetchGetRequest({
      endpoint: routeShop.apiSongData(),
      errorMessage: "の取得に失敗しました。",
      params: params,
    });

    if (res.success) itemList = res.data;
  };

  const getSongIncludeInstrument = (
    songList: Products_SongDataForSearch[],
    instrumentId: number,
  ) => {
    const songs = songList.filter((song) => {
      const includesId = song.items.map((item) =>
        item.instrument.map((inst) => inst.id).includes(instrumentId),
      );
      return includesId.includes(true);
    });
    return songs;
  };

  $: instrument = instruments.find((inst) => inst.id == params.instrumentId);
  $: tableItems =
    itemList && params
      ? getSongIncludeInstrument(itemList, Number(params.instrumentId))
      : [];
  // $: console.log("instruments", instruments);
  // $: console.log(("itemList", itemList));
  // $: console.log("prams", params.instrumentId, instrument);
  // $: console.log("tableItems", tableItems);
</script>

<div class="px-2 px-md-4 my-3">
  <div class="row">
    <div class="col-12">
      {#if instrument}
        <h3 class="text-muted font-weight-bold">
          {instrument.name} 曲目リスト
        </h3>
      {/if}
      {#if tableItems}
        <SongSearchTable
          bind:items={tableItems}
          bind:instrumentId={params.instrumentId}
        >
          {#if !tableItems.length}
            <Cell>
              <div class="my-4 px-4 text-muted font-weight-bolder">
                現在、この機種に対応している商品はございません。
              </div>
            </Cell>
          {/if}
        </SongSearchTable>
      {/if}
    </div>
  </div>
  <div class="row my-3">
    <div class="col-12 d-flex justify-content-center">
      <button
        class="btn btn-primary"
        on:click={() => navigate(routeShop.songSearch())}>機種一覧に戻る</button
      >
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-4">
      <div class="ps-3">
        <h4 class="text-muted font-weight-bolder">機種概要</h4>
        <div class="px-3">
          <ModelSpec {instrument}></ModelSpec>
        </div>
      </div>
    </div>
  </div>
</div>
